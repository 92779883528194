import Entity from '../entity'

export default class SaleCharge extends Entity<number>{
    sale_id: number;
    invoice_charge_id: number;
    rate: number;
    base: number;
    charge: number;
    total: number;
    payment_code: string;
    bank_account: string;
    invoice_charge: any;
}


